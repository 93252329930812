import React from 'react';

const Footer = () => {
    return (
        <div style={{textAlign:"center", backgroundColor:"#1C2A2B",color:"white",padding:"15px 0"}}>
            <p>Copyright reserved by Jubair</p>
        </div>
    );
};

export default Footer;